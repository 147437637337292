import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { SelectDictionary } from 'src/shared/ui/_inputs/SelectDictionary';
import { TreeSelectDictionary } from 'src/shared/ui/_inputs/TreeSelectDictionary';
import { useUrlFilterParams } from '../../hooks/useUrlFilterParams';
import { ifEmptySetNull } from '../../lib/ifEmptySetNull';
import { DICT_DM } from '../QcDashboardPage/consts';
import { mergeRegionsSubregionsCountries } from '../QcDashboardPage/lib/mergeRegionsSubregionsCountries/mergeRegionsSubregionsCountries';
import s from './Filters.module.scss';
import { handleDefaultDM } from '../../lib/handleDefaultDM';
import { handleDefaultRepPeriod } from '../../lib/handleDefaultRepPeriod';
import { getIndustryFromURL } from '../../lib/getIndustryFromURL';
import { getRegionNameFromURL } from '../../lib/getRegionNameFromURL';

export const Filters: FC = () => {
	// * Selectors
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);
	const dictReportingPeriods = dictionaries?.reportingPriods ?? [];
	const dictRegions = dictionaries?.regions ?? [];
	const dictIndustries = dictionaries?.industries ?? [];

	const { reportingPeriodState, regionState, industryState, DMState, setSearchParams } = useUrlFilterParams();
	const sendToURL = { reportingPeriod: reportingPeriodState, region: regionState, industry: industryState, DM: DMState };

	const dictRegionsSubregionsCountries = dictRegions ? mergeRegionsSubregionsCountries(dictRegions) : [];

	const handleOnChangeReportingPeriod = (selectedValue: string) => {
		setSearchParams({ ...sendToURL, reportingPeriod: ifEmptySetNull(selectedValue) });
	};

	const handleOnChangeRegion = (selectedValue: string) => {
		setSearchParams({ ...sendToURL, region: ifEmptySetNull(selectedValue) });
	};

	const handleOnChangeIndustries = (selectedValue: string) => {
		setSearchParams({ ...sendToURL, industry: ifEmptySetNull(selectedValue) });
	};

	const handleOnChangeDM = (selectedValue: string) => {
		const idDM = DICT_DM.find(elem => elem.id === selectedValue);
		setSearchParams({ ...sendToURL, DM: idDM ? idDM.id : handleDefaultDM() });
	};

	const getDMfromURL = () => {
		const activeValue = DICT_DM.find(dm => dm.id.toString() === DMState);
		const newDM = activeValue ? activeValue.name : handleDefaultDM(true);
		return newDM;
	};

	const getRepPeriodNameFromURL = () => {
		const activeValue = dictReportingPeriods?.find(period => period.id.toString() === reportingPeriodState);
		const newRepPeriod = activeValue ? activeValue.name : handleDefaultRepPeriod(dictReportingPeriods, true);
		return newRepPeriod;
	};

	// * Render
	return (
		<div
			className={s.container}
			key="qcSelectors"
		>
			{dictReportingPeriods && (
				<SelectDictionary
					key="qcReportingPeriod"
					label="Reporting period"
					defaultValue={getRepPeriodNameFromURL}
					onChange={handleOnChangeReportingPeriod}
					options={[...dictReportingPeriods.map(reportingPeriod => ({ id: reportingPeriod.id, name: reportingPeriod.name }))]}
				/>
			)}

			{dictRegions && (
				<TreeSelectDictionary
					label="Region, subregion and country"
					options={dictRegions.map(region => ({
						value: region.id,
						title: region.name,
						children: region.children?.map(subRegion => ({
							value: subRegion.id,
							title: subRegion.name,
							children: subRegion.children?.map(country => ({
								value: country.id,
								title: country.name,
							})),
						})),
					}))}
					defaultValue={getRegionNameFromURL(dictRegionsSubregionsCountries, regionState)}
					onChange={handleOnChangeRegion}
					addEmpty={true}
				/>
			)}

			{dictIndustries && (
				<SelectDictionary
					key="qcIndustries"
					label="Industries"
					defaultValue={getIndustryFromURL(dictIndustries, industryState)}
					onChange={handleOnChangeIndustries}
					options={[{ id: '', name: '' }, ...dictIndustries.map(industry => ({ id: industry.id, name: industry.name }))]}
				/>
			)}

			<SelectDictionary
				key="qcDM"
				label="DM"
				defaultValue={getDMfromURL()}
				onChange={handleOnChangeDM}
				options={[...DICT_DM]}
			/>
		</div>
	);
};
