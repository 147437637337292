import { FC, useState } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { Tabs } from 'src/shared/ui/Tabs';
import { SourceType } from '../../../_BLL/copper_menu/types';
import { CompanyMergeForm } from '../CompanyMergeForm';
import { PeopleMergeForm } from '../PeopleMergeForm';
import { SilverFilter } from '../SilverFilter/SilverFilter';
import { ReportingPeriodError } from '../ReportingPeriodError/ReportingPeriodError';

interface Props {
	sources: [SourceType, SourceType];
	dictionaries: Dictionaries;
}

export const SilverMode: FC<Props> = props => {
	const {
		sources, //
		dictionaries,
	} = props;

	// * Selectors
	// - Company
	const companyConflicts = useAppSelector(state => state.mergeToolDataPoints.companyConflicts);
	const sourceCompany = useAppSelector(state => state.mergeToolCompanySearch.sourceCompany);
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);
	// - People
	const persons = useAppSelector(state => state.mergeToolDataPoints.peopleConflicts);
	// - General
	const reportingPeriodError = useAppSelector(state => state.mergeToolDataPoints.reportingPeriodError);

	// * Conflict mode
	const [conflictMode, setConflictMode] = useState<'company' | 'people'>('company');

	const selectTab = (tab: 'company' | 'people') => {
		setConflictMode(tab);
	};

	// * Conditions
	const companiesSelected = sourceCompany && targetCompany;
	const companyFormReady = companyConflicts && companiesSelected && conflictMode === 'company';
	const peopleFormReady = persons && persons.length > 0 && companiesSelected && conflictMode === 'people';

	// * Container
	return (
		<>
			<SilverFilter />

			{companiesSelected && (
				<Tabs
					selectedTab={conflictMode}
					tabs={['company', 'people']}
					selectTab={selectTab}
				/>
			)}

			{reportingPeriodError ? (
				<ReportingPeriodError />
			) : (
				<>
					{companyFormReady && (
						<CompanyMergeForm
							sources={sources}
							targetCompany={targetCompany}
							companyConflicts={companyConflicts}
							dictionaries={dictionaries}
							sourceCompany={sourceCompany}
						/>
					)}

					{/* People form	*/}
					{peopleFormReady && (
						<PeopleMergeForm
							targetCompany={targetCompany}
							persons={persons}
						/>
					)}
				</>
			)}
		</>
	);
};
