import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators';
import { useAppSelector } from 'src/app/redux/createAction';
import { RouterPath } from 'src/app/router/AppRouter';
import { Company } from 'src/pages/DashboardPage/_BLL/company/types/types';
import { AddNewCompany } from 'src/pages/MainPage/ui/AddNewCompany';
import { Kpi } from 'src/pages/MainPage/ui/Kpi';
import { SearchBar } from 'src/pages/MainPage/ui/SearchBar';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { Modal } from 'src/shared/ui/_modals/Modal';
import s from './MainSearchPage.module.scss';
import { MetaContextProvider } from 'src/app/context/useMetaContext';

export const MainSearchPage = () => {
	// * Router
	const navigate = useNavigate();

	// * Selectors
	const { userData } = useAppSelector(state => state.auth);
	const companyMeta = useAppSelector(state => state.metaDictionaries.meta.company);
	const personMeta = useAppSelector(state => state.metaDictionaries.meta.person);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);
	const companyTemplate = dictionaries && (dictionaries.entityTemplates.find(template => template.entityName === 'company') as Company);

	// * Add new company modal
	const [showModal, setShowModal] = useState<boolean>(false);
	const toggleModal = () => setShowModal(!showModal);

	// * Conditions
	const showAddNewCompany = userData && userData.accessRights.includes('AddCompanies');
	const showAdminTool = userData && userData.accessRights.includes('ExcelLoader');
	const showMergeTool = userData && userData.accessRights.includes('MergeTool');
	const showQcDashboard = userData && userData.accessRights.includes('QcDashboard');

	// * Render
	return (
		<>
			{companyTemplate && dictionaries && companyMeta && personMeta && (
				<MetaContextProvider
					companyMeta={companyMeta}
					personMeta={personMeta}
					dictionaries={dictionaries}
				>
					<Modal
						isOpen={showModal}
						onClose={toggleModal}
					>
						<AddNewCompany
							companyTemplate={companyTemplate}
							dictionaries={dictionaries}
						/>
					</Modal>

					<PageDecorator>
						<div className={s.container}>
							<div className={s.nav}>
								{showAddNewCompany && <ButtonPrimary onClick={toggleModal}>Add new company</ButtonPrimary>}

								<div className={s.nav_right}>
									{showAdminTool && <ButtonPrimary onClick={() => navigate(RouterPath.adminCompany)}>Admin</ButtonPrimary>}
									{showMergeTool && <ButtonPrimary onClick={() => navigate(RouterPath.merge)}>Merge tool</ButtonPrimary>}
									{showQcDashboard && <ButtonPrimary onClick={() => navigate(RouterPath.qcDashboard + '?reportingPeriod=8&region=null&industry=null&DM=0')}>QC Dashboard</ButtonPrimary>}
								</div>
							</div>

							<SearchBar />
						</div>

						<div className={s.container}>
							<Kpi />
						</div>
					</PageDecorator>
				</MetaContextProvider>
			)}
		</>
	);
};
