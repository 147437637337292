import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'src/app/redux/createAction';
import { DICT_DM } from '../ui/QcDashboardPage/consts';
import { mergeRegionsSubregionsCountries } from '../ui/QcDashboardPage/lib/mergeRegionsSubregionsCountries/mergeRegionsSubregionsCountries';
import { handleDefaultDM } from '../lib/handleDefaultDM';
import { handleDefaultRepPeriod } from '../lib/handleDefaultRepPeriod';
import { getIndustryFromURL } from '../lib/getIndustryFromURL';
import { getRegionNameFromURL } from '../lib/getRegionNameFromURL';

export const useUrlFilterParams = () => {
	// * History API
	const [searchParams, setSearchParams] = useSearchParams();

	const reportingPeriodfromURL = searchParams.get('reportingPeriod');
	const regionFromURL = searchParams.get('region');
	const industryFromURL = searchParams.get('industry');
	const DMfromURL = searchParams.get('DM');

	// * Selectors
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);
	const dictReportingPeriods = dictionaries?.reportingPriods ?? [];
	const dictRegions = dictionaries?.regions ?? [];
	const dictIndustries = dictionaries?.industries ?? [];

	const dictRegionsSubregionsCountries = dictRegions ? mergeRegionsSubregionsCountries(dictRegions) : [];

	const reportingPeriodState = reportingPeriodfromURL && reportingPeriodfromURL !== 'null' ? reportingPeriodfromURL : handleDefaultRepPeriod(dictReportingPeriods);
	const regionState = regionFromURL && regionFromURL !== 'null' ? regionFromURL : getRegionNameFromURL(dictRegionsSubregionsCountries, regionFromURL);
	const industryState = industryFromURL && industryFromURL !== 'null' ? industryFromURL : getIndustryFromURL(dictIndustries, industryFromURL);
	const DMState = DMfromURL && DMfromURL !== 'null' ? DMfromURL : handleDefaultDM();

	return { reportingPeriodState, regionState, industryState, DMState, setSearchParams };
};
